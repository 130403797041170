import { colors, dimensions } from '../../../styles';

import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link as ReactScrollLink } from 'react-scroll';

export const HamburgerIcon = styled(MenuIcon)`
  font-size: 60px;
  @media only screen and (max-width: ${dimensions.mobileWidthCarousel}) {
    // screens less than 450 pixel
    font-size: 40px;
  }
`;

export const CloseMuiIcon = styled(CloseIcon)`
  position: absolute;
  top: 0;
  right: 0;
  font-size: 60px;
  :hover {
    cursor: pointer;
  }
  @media only screen and (max-width: ${dimensions.mobileWidthCarousel}) {
    // screens less than 450 pixel
    font-size: 40px;
  }
`;

export const HamburgerContainer = styled.div<{ thresholdWasReached: boolean }>`
  // border: 1px solid yellow;
  cursor: pointer;
  margin-right: 5px;
  :hover {
    background: ${({ thresholdWasReached }) =>
      thresholdWasReached ? 'rgba(240, 240, 240, 0.1)' : ''};
  }
  @media only screen and (min-width: ${dimensions.standardWidthTabletsMobile}) {
    // screens larger than 785 pixel approx
    display: none;
  }
`;

export const ReactScrollLinkHamburgerButtons = styled(ReactScrollLink)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 80%;
  font-weight: bold;
  height: 50px;
  width: 100% !important;
  cursor: pointer;
  border: 0.5px solid ${colors.newDarkGreen};
  background: ${colors.lightGreen};
  @media only screen and (min-height: 800px) {
    // screens with higher height than 800 pixels
    font-size: 20px;
    height: 80px;
  }
`;

export const ModalWrapper = styled.div`
  position: relative;
  // border: 2px solid yellow;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: black;
  outline: none;
  overflow: scroll;
`;

export const StyledLinkContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const HamburgerFooterDiv = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100%;
  height: 100%;
`;

export const ContainerDiv = styled.div`
  // border: yellow 1px solid;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: space-between;
  height: 100%;
  width: 100%;
  @media only screen and (min-width: 1280px) {
    /* Styles for screens bigger than 1280px pixels */
    width: 70%;
  }
`;

export const HamburgerHeaderScreenDiv = styled.div`
  // border: 2px dashed yellow;
  margin-top: 60px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const NameH1 = styled.h1`
  font-family: 'Martian Mono';
  font-weight: bold;
  font-size: 32px;
  margin: 20px 0 0 0;
  @media only screen and (min-height: 800px) {
    // screens with higher height than 800 pixels
    font-size: 38px;
  }
`;

export const RoleH2 = styled.h2`
  font-size: 18px;
  color: #ffffff82;
  margin-bottom: 5px;
`;

const bounceAnimation = keyframes`
  0% {
    transform: translateX(-35px);
  }
  20% {
    transform: translateX(35px);
  }
  40% {
    transform: translateX(-10px);
  }
  60% {
    transform: translateX(3px);
  }
  80% {
    transform: translateX(-2px);
  }
  100% {
    transform: translateX(0);
  }
`;

export const HamburgerLogoImg = styled.img`
  width: 150px;
  animation: ${bounceAnimation} 0.7s ease-in-out;
  animation-delay: 0.2s;
  animation-fill-mode: forwards;
`;
