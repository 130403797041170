import { GlobeObj } from '../../../types/globeObject';

export const globeData: GlobeObj[] = [
  {
    location: 'Germany',
    reason: 'Current Base',
    additionalReason: 'Bachelor Studies',
    lat: 48,
    lng: 8.0,
    size: 20,
    color: 'red',
  },
  {
    location: 'Spain',
    reason: 'Master Studies',
    lat: 42,
    lng: -4,
    size: 20,
    color: 'blue',
  },
  {
    location: 'Bolivia',
    reason: 'Origin',
    lat: -12,
    lng: -58,
    size: 20,
    color: 'white',
  },
  {
    location: 'Brunei',
    reason: 'School',
    lat: -186,
    lng: -66,
    size: 20,
    color: 'blue',
  },
];
