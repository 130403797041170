import { colors } from '../../../../styles';
import { media } from '../../../../constants/mediaConstants';
import * as S from './MyVerticalTimeline.styled';

import 'react-vertical-timeline-component/style.min.css';
import { ResumeSection } from '../../../../types/profileDataTypes';
import { useInView } from 'react-intersection-observer';
import { useEffect, useState } from 'react';
import { EndVTElement } from './EndVTElement';
import { intersectionConfig } from './intersectionConfig';
import { TimelineFilter } from './TimelineFilter';
import { CustomVTElement } from './CustomVTElement';
import { useMediaQuery } from '@mui/material';

export const MyVerticalTimeline = ({
  resumeData,
}: {
  resumeData: ResumeSection;
}) => {
  const [screenHeight, setScreenHeight] = useState<number>(window.innerHeight);
  const screenWidthIsLarge = useMediaQuery('(min-width:700px)');
  const [isExperience, setIsExperience] = useState<boolean>(true);
  const [isEducation, setIsEducation] = useState<boolean>(false);
  const [artechWasScrolled, setArtechWasScrolled] = useState<boolean>(false);
  const [accWasScrolled, setAccWasScrolled] = useState<boolean>(false);
  const [gsgWasScrolled, setGsgWasScrolled] = useState<boolean>(false);
  const [sixtWasScrolled, setSixtWasScrolled] = useState<boolean>(false);

  const [spicedWasScrolled, setSpicedWasScrolled] = useState<boolean>(false);
  const [upfWasScrolled, setUpfWasScrolled] = useState<boolean>(false);
  const [constructorWasScrolled, setConstructorWasScrolled] =
    useState<boolean>(false);

  const [artechRef, artechInView] = useInView(intersectionConfig);
  const [accRef, accInView] = useInView(intersectionConfig);
  const [gsgRef, gsgInView] = useInView(intersectionConfig);
  const [sixtRef, sixtInView] = useInView(intersectionConfig);

  const [spicedRef, spicedInView] = useInView(intersectionConfig);
  const [upfRef, upfInView] = useInView(intersectionConfig);
  const [constructorRef, constructorInView] = useInView(intersectionConfig);

  useEffect(() => {
    const handleResize = () => {
      setScreenHeight(window.innerHeight);
    };

    const updateScrollState = () => {
      setArtechWasScrolled(artechInView);
      setAccWasScrolled(accInView);
      setGsgWasScrolled(gsgInView);
      setSixtWasScrolled(sixtInView);
      setSpicedWasScrolled(spicedInView);
      setUpfWasScrolled(upfInView);
      setConstructorWasScrolled(constructorInView);
    };

    window.addEventListener('resize', handleResize);
    updateScrollState();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [
    artechInView,
    accInView,
    gsgInView,
    sixtInView,
    spicedInView,
    upfInView,
    constructorInView,
  ]);

  const work = resumeData.work;
  const artechObj = work.find((workItem) => workItem.company === 'Artech-IS');
  const accObj = work.find((workItem) => workItem.company === 'Accenture');
  const gsgObj = work.find(
    (workItem) => workItem.company === 'GSG (Rocket Internet)'
  );
  const sixtObj = work.find(
    (workItem) => workItem.company === 'Sixt rent a car'
  );

  const education = resumeData.education;
  const spicedObj = education.find(
    (educationItem) => educationItem.school === 'Spiced Academy Bootcamp'
  );
  const upfObj = education.find(
    (educationItem) => educationItem.school === 'Universitat Pompeu Fabra'
  );
  const constructorObj = education.find(
    (educationItem) => educationItem.school === 'Constructor University Bremen'
  );

  const handleExperience = () => {
    setIsExperience(true);
    setIsEducation(false);
  };

  const handleEducation = () => {
    setIsEducation(true);
    setIsExperience(false);
  };

  return (
    <>
      <TimelineFilter
        isExperience={isExperience}
        handleExperience={handleExperience}
        isEducation={isEducation.toString()}
        handleEducation={handleEducation}
      />
      <S.VerticalTimelinePositioned lineColor={colors.lightGreenTransparent}>
        {isExperience ? <S.StyledWorkIcon /> : <S.StyledEduIcon />}
        {isExperience && (
          <>
            {/*ARTECH*/}
            <CustomVTElement
              name={
                screenWidthIsLarge
                  ? (artechObj?.company as string)
                  : (artechObj?.company_small as string)
              }
              positionOrDegree={
                screenWidthIsLarge
                  ? (artechObj?.title as string)
                  : (artechObj?.title_small as string)
              }
              date={
                screenWidthIsLarge
                  ? (artechObj?.years as string)
                  : (artechObj?.years_small as string)
              }
              description={
                screenWidthIsLarge
                  ? (artechObj?.description as string)
                  : (artechObj?.description_small as string)
              }
              website={artechObj?.website as string}
              customRef={artechRef}
              customWasScrolled={artechWasScrolled}
              customMediaIconProp={media.artechIcon}
              screenHeightProp={screenHeight}
            />
            {/*ACCENTURE*/}
            <CustomVTElement
              name={
                screenWidthIsLarge
                  ? (accObj?.company as string)
                  : (accObj?.company_small as string)
              }
              positionOrDegree={
                screenWidthIsLarge
                  ? (accObj?.title as string)
                  : (accObj?.title_small as string)
              }
              date={
                screenWidthIsLarge
                  ? (accObj?.years as string)
                  : (accObj?.years_small as string)
              }
              description={
                screenWidthIsLarge
                  ? (accObj?.description as string)
                  : (accObj?.description_small as string)
              }
              website={accObj?.website as string}
              customRef={accRef}
              customWasScrolled={accWasScrolled}
              customMediaIconProp={media.accIcon}
              screenHeightProp={screenHeight}
            />
            {/*GSG*/}
            <CustomVTElement
              name={
                screenWidthIsLarge
                  ? (gsgObj?.company as string)
                  : (gsgObj?.company_small as string)
              }
              positionOrDegree={
                screenWidthIsLarge
                  ? (gsgObj?.title as string)
                  : (gsgObj?.title_small as string)
              }
              date={
                screenWidthIsLarge
                  ? (gsgObj?.years as string)
                  : (gsgObj?.years_small as string)
              }
              description={
                screenWidthIsLarge
                  ? (gsgObj?.description as string)
                  : (gsgObj?.description_small as string)
              }
              website={gsgObj?.website as string}
              customRef={gsgRef}
              customWasScrolled={gsgWasScrolled}
              customMediaIconProp={media.gsgIcon}
              screenHeightProp={screenHeight}
            />
            {/*Sixt*/}
            <CustomVTElement
              name={
                screenWidthIsLarge
                  ? (sixtObj?.company as string)
                  : (sixtObj?.company_small as string)
              }
              positionOrDegree={
                screenWidthIsLarge
                  ? (sixtObj?.title as string)
                  : (sixtObj?.title_small as string)
              }
              date={
                screenWidthIsLarge
                  ? (sixtObj?.years as string)
                  : (sixtObj?.years_small as string)
              }
              description={
                screenWidthIsLarge
                  ? (sixtObj?.description as string)
                  : (sixtObj?.description_small as string)
              }
              website={sixtObj?.website as string}
              customRef={sixtRef}
              customWasScrolled={sixtWasScrolled}
              customMediaIconProp={media.sixtIcon}
              screenHeightProp={screenHeight}
            />
          </>
        )}
        {isEducation && (
          <>
            {/*Spiced*/}
            <CustomVTElement
              name={
                screenWidthIsLarge
                  ? (spicedObj?.school as string)
                  : (spicedObj?.school_small as string)
              }
              positionOrDegree={
                screenWidthIsLarge
                  ? (spicedObj?.degree as string)
                  : (spicedObj?.degree_small as string)
              }
              date={spicedObj?.graduated as string}
              description={
                screenWidthIsLarge
                  ? (spicedObj?.description as string)
                  : (spicedObj?.description_small as string)
              }
              website={spicedObj?.website as string}
              customRef={spicedRef}
              customWasScrolled={spicedWasScrolled}
              customMediaIconProp={media.spicedIcon}
              screenHeightProp={screenHeight}
            />
            {/*UPF*/}
            <CustomVTElement
              name={
                screenWidthIsLarge
                  ? (upfObj?.school as string)
                  : (upfObj?.school_small as string)
              }
              positionOrDegree={
                screenWidthIsLarge
                  ? (upfObj?.degree as string)
                  : (upfObj?.degree_small as string)
              }
              date={upfObj?.graduated as string}
              description={
                screenWidthIsLarge
                  ? (upfObj?.description as string)
                  : (upfObj?.description_small as string)
              }
              website={upfObj?.website as string}
              customRef={upfRef}
              customWasScrolled={upfWasScrolled}
              customMediaIconProp={media.upfIcon}
              screenHeightProp={screenHeight}
            />
            {/*Constructor*/}
            <CustomVTElement
              name={
                screenWidthIsLarge
                  ? (constructorObj?.school as string)
                  : (constructorObj?.school_small as string)
              }
              positionOrDegree={
                screenWidthIsLarge
                  ? (constructorObj?.degree as string)
                  : (constructorObj?.degree_small as string)
              }
              date={constructorObj?.graduated as string}
              description={
                screenWidthIsLarge
                  ? (constructorObj?.description as string)
                  : (constructorObj?.description_small as string)
              }
              website={constructorObj?.website as string}
              customRef={constructorRef}
              customWasScrolled={constructorWasScrolled}
              customMediaIconProp={media.coIcon}
              screenHeightProp={screenHeight}
            />
          </>
        )}
        {/* End */}
        <EndVTElement />
      </S.VerticalTimelinePositioned>
    </>
  );
};
