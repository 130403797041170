import { CircularProgress } from '@mui/material';
import { media } from '../../constants/mediaConstants';
import { colors } from '../../styles';

export const Loader: React.FC = () => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        height: '100%',
        width: '100%',
        background: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.8)), url(${media.backgroundNoise})`,
        backgroundColor: colors.backgroundDark,
      }}
    >
      <CircularProgress color="inherit" size={'4rem'} />
    </div>
  );
};
