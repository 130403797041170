import styled from '@emotion/styled';

import { colors } from '../../../styles';
import { Button } from '@mui/material';

export const AboutComponentContainer = styled.section`
  display: flex;
  // border: 10px dashed black;
  justify-content: center;
`;

export const AboutMeDivWrapper = styled.div`
  width: 100%;
  max-width: 96%;
  @media only screen and (min-width: 1280px) {
    /* Styles for screens bigger than 1280px pixels */
    width: 70%;
  }
  // border: 2px solid blue;
  margin-top: 60px;
`;

export const FirstCell = styled.div`
  // border: 3px solid blue;
  display: flex;
  width: 50%;
  padding: 0 0 50px 50px;
  @media only screen and (max-width: 2000px) {
    /* Styles for screens smaller than 2000 pixels */
    width: 100%;
  }
  @media only screen and (max-width: 800px) {
    /* Styles for screens smaller than 800 pixels */
    flex-direction: column;
  }
  @media only screen and (max-width: 715px) {
    /* Styles for screens smaller than 715 pixels */
    align-items: center;
    padding-left: 0;
  }
`;

export const SecondCell = styled.div`
  display: flex;
  // border: 10px solid red;
  width: 50%;
  padding: 0 0 0 15px;
  @media only screen and (max-width: 2000px) {
    /* Styles for screens smaller than 2000 pixels */
    width: 100%;
    padding-left: 50px;
  }
  @media only screen and (max-width: 1450px) {
    /* Styles for screens smaller than 1450 pixels */
    padding-left: 50px;
    width: 100%;
  }
  @media only screen and (max-width: 715px) {
    /* Styles for screens smaller than 715 pixels */
    flex-direction: column;
    align-items: center;
    padding-left: 0;
  }
`;

export const FirstCellFirstContainerFramer = styled.div`
  max-width: 520px;
  @media only screen and (max-width: 715px) {
    /* Styles for screens smaller than 715 pixels */
    width: 80%;
  }
`;

export const FirstCellSecondContainerFramer = styled.div`
  // border: 22px dashed yellow;
  max-width: 520px;
  margin-left: 25px;

  display: flex;
  flex-direction: column;
  align-items: center;

  p:first-of-type {
    margin-top: 50px;
  }

  @media only screen and (max-width: 800px) {
    /* Styles for screens smaller than 800 pixels */
    margin-left: 0;
    p:first-of-type {
      display: none;
    }
  }

  @media only screen and (max-width: 715px) {
    /* Styles for screens smaller than 715 pixels */
    width: 80%;
  }
`;

export const AboutFlexContainer = styled.div`
  display: flex;
  @media only screen and (max-width: 2000px) {
    /* Styles for screens smaller than 2000 pixels */
    flex-direction: column;
  }
`;

export const DetailsH1 = styled.h1`
  margin-top: 0;
`;

export const SecondCellFirstContainerFramer = styled.div`
  margin: 0 0 15px 5px;
  // border: 25px dotted yellow;
  width: 50%;
  @media only screen and (max-width: 715px) {
    /* Styles for screens smaller than 715 pixels */
    width: 80%;
  }
`;

export const MuiButton = styled(Button)`
  margin-top: 15px;
  background: ${colors.mediumGreen};
  font-weight: 900;
  padding: 10px;
  border-radius: 4px;
  transition: transform 0.3s ease;
  :hover {
    background: ${colors.lightGreen};
    transform: scale(1.1);
  }
`;
export const GlobeContainerTranslateWrapper = styled.div`
  transform: translateY(-75px);
  @media only screen and (max-width: 460px) {
    /* Styles for screens smaller than 460 pixels */
    transform: translateY(-50px);
  }
`;

export const GlobeContainerFramer = styled.div`
  display: flex;
  justify-content: center;
  cursor: grab;
  // border: 2px solid purple;
  @media only screen and (max-width: 715px) {
    /* Styles for screens smaller than 715 pixels */
    width: 100%;
  }
`;
