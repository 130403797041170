import { Header } from './components/sections/Header/Header';
import { About } from './components/sections/About/About';
import { Resume } from './components/sections/Resume/Resume';
import { Projects } from './components/sections/Projects/Projects';
import { Footer } from './components/sections/Footer/Footer';
import profileData from './constants/profileData.json';
import {
  MainSection,
  PortfolioSection,
  ResumeSection,
  SocialLink,
} from './types/profileDataTypes';
import { GeneralAppStyles } from './App.styled';
import { Loader } from './components/molecules/Loader';

function App() {
  if (!profileData) {
    return <Loader></Loader>;
  }
  const profileDataMain = profileData.main;
  const profileDataMainSocial = profileData.main.social;
  const profileDataResume = profileData.resume;
  const profileDataPorfolio = profileData.portfolio;
  return (
    <GeneralAppStyles>
      <Header />
      <About profileDataMain={profileDataMain as MainSection} />
      <Resume profileDataResume={profileDataResume as ResumeSection} />
      <Projects
        profileDataPortfolio={profileDataPorfolio as PortfolioSection}
      />
      <Footer profileDataMainSocial={profileDataMainSocial as SocialLink[]} />
    </GeneralAppStyles>
  );
}

export default App;
