const colors = {
  standardGreen: '#346055',
  newDarkGreen: '#303e49',
  backgroundDark: '#111111',
  lightGreen: '#249e67',
  newLighterGreen: '#29c27e',
  lightGreenTransparent: '#249e6752',
  lightGreenMediumTransparent: '#249E6799',
  mediumGreen: '#2C7755',
  mediumGreenRgb: '44, 119, 85',
  endOfTimelineDarkGreen: '#334f42',
  hamburgerSelectedGreen: '#249e67bf',
  transparent: 'rgba(0, 0, 0, 0)',
  techLabelGreen: '#3460556b',
  techLabelGreenDarker: '#346055bd',
  carouselCardDarkBackgroundGreen: '#286e4e',
};

const shadows = {
  greenGlow: '#255641',
  yellowGlow: '#fff400',
  yellowGlowMobile: '#f1ff05bf',
};

const dimensions = {
  standardWidthTabletsMobile: '785px',
  mobileWidthCarousel: '450px',
};

export { colors, shadows, dimensions };
