import { colors } from '../../../styles';

import styled from '@emotion/styled';
import GitHubIcon from '@mui/icons-material/GitHub';
import TwitterIcon from '@mui/icons-material/Twitter';
import LinkedInIcon from '@mui/icons-material/LinkedIn';

export const FooterComponentContainer = styled.section`
  display: flex;
  justify-content: center;
  background: black;
  height: 150px;
`;

export const FooterDivWrapper = styled.div`
  max-width: 96%;
  @media only screen and (min-width: 1280px) {
    /* Styles for screens bigger than 1280px pixels */
    width: 70%;
  }
`;

export const FlexContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* Optionally set a height to make sure the container fills the viewport vertically */
`;

export const IconDivContainer = styled.div`
  display: flex;
  align-items: center;
  height: 60px;
`;

export const FooterAnchors = styled.a`
  margin: 0 8px;
`;

export const SGitHubIcon = styled(GitHubIcon)`
  color: white;
  height: 30px;
  width: 30px;
  transition: background-color 0.6s, color 0.6s;
  &:hover {
    color: ${colors.lightGreen};
  }
`;

export const STwitterIcon = styled(TwitterIcon)`
  color: white;
  height: 30px;
  width: 30px;
  transition: background-color 0.6s, color 0.6s;
  &:hover {
    color: ${colors.lightGreen};
  }
`;

export const SLinkedInIcon = styled(LinkedInIcon)`
  color: white;
  height: 35px;
  width: 35px;
  transition: background-color 0.6s, color 0.6s;
  &:hover {
    color: ${colors.lightGreen};
  }
`;
