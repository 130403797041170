import { SocialLink } from '../../../types/profileDataTypes';
import * as S from './Footer.styled';

export interface FooterProps {
  profileDataMainSocial: SocialLink[];
}

export const Footer: React.FC<FooterProps> = ({ profileDataMainSocial }) => {
  return (
    <S.FooterComponentContainer>
      <S.FooterDivWrapper>
        <S.FlexContainer>
          <S.IconDivContainer>
            <S.FooterAnchors
              href={profileDataMainSocial[0].url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <S.SGitHubIcon />
            </S.FooterAnchors>
            <S.FooterAnchors
              href={profileDataMainSocial[1].url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <S.SLinkedInIcon />
            </S.FooterAnchors>
            <S.FooterAnchors
              href={profileDataMainSocial[2].url}
              target="_blank"
              rel="noopener noreferrer"
            >
              <S.STwitterIcon />
            </S.FooterAnchors>
          </S.IconDivContainer>
        </S.FlexContainer>
      </S.FooterDivWrapper>
    </S.FooterComponentContainer>
  );
};
