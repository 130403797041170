import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { media } from './constants/mediaConstants';
import PreloadMedia from './components/molecules/PreloadMedia';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <PreloadMedia images={Object.values(media)}>
      <App />
    </PreloadMedia>
  </React.StrictMode>
);
