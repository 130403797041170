import Globe from 'react-globe.gl';

import { colors } from '../../../styles';
import { useEffect, useRef } from 'react';
import { media } from '../../../constants/mediaConstants';
import { globeData } from './globeObject';
import { GlobeObj } from '../../../types/globeObject';
import { createTooltipElement } from './toolTipElementVanilla';
import { useMediaQuery } from '@mui/material';

export const GlobeComponent = () => {
  const isMobile = useMediaQuery('(max-width:450px)');
  const globeEl = useRef<any>();

  useEffect(() => {
    if (globeEl.current) {
      globeEl.current.pointOfView({ lat: 49.0, lng: 11.0, altitude: 3 }, 2000);
      globeEl.current.controls().enableZoom = false;
    }
  }, [globeEl]);

  return (
    <div>
      <Globe
        ref={globeEl}
        globeImageUrl={media.globeTexture}
        htmlElementsData={globeData}
        htmlElement={(d: object) => {
          const globeObj = d as GlobeObj;

          const el = document.createElement('div');
          el.innerHTML = media.globeMarker;
          el.style.color = globeObj.color;
          el.style.width = `${globeObj.size}px`;
          el.style.pointerEvents = 'auto';
          el.style.cursor = 'pointer';
          el.style.zIndex = '-1000';

          // Add tooltip functionality
          el.addEventListener('mouseenter', () => {
            tooltip.style.visibility = 'visible';
            tooltip.style.opacity = '1';
          });

          el.addEventListener('mouseleave', () => {
            tooltip.style.visibility = 'hidden';
            tooltip.style.opacity = '0';
          });
          // Create and append tooltip element
          const tooltip = createTooltipElement(globeObj);
          el.appendChild(tooltip);
          return el;
        }}
        height={isMobile ? 250 : 400}
        width={isMobile ? 250 : 400}
        atmosphereColor={colors.lightGreen}
        atmosphereAltitude={0.4}
        backgroundColor={colors.transparent}
      />
    </div>
  );
};
