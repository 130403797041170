import React, { useState } from 'react';
import { Loader } from './Loader';

interface PreloadMediaProps {
  images: string[];
  children: React.ReactNode;
}

const PreloadMedia: React.FC<PreloadMediaProps> = ({ images, children }) => {
  //TODO: TRY TO MAKE THE PRELOADING PROPERLY
  const [loaded, setLoaded] = useState(false);

  const onLoad = () => {
    setLoaded(true);
  };

  images.forEach((imageSrc) => {
    const img = new Image();
    img.src = imageSrc;
    img.onload = onLoad;
  });
  return loaded ? <>{children}</> : <Loader />;
};

export default PreloadMedia;
