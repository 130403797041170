import {
  CV,
  globeMarker,
  backgroundNoise,
  profilePic,
  globeTexture,
  artechIcon,
  accIcon,
  gsgIcon,
  sixtIcon,
  spicedIcon,
  upfIcon,
  coIcon,
  minusIcon,
  SSBImage,
  SSBGif,
  cSmall,
  ontologySmall,
  training,
  trainingGif,
  story,
  storyGif,
  github,
  mb,
  mbGif,
  social,
  rwIconGreen,
} from '../assets';

const media = {
  CV,
  globeMarker,
  backgroundNoise,
  profilePic,
  globeTexture,
  artechIcon,
  accIcon,
  gsgIcon,
  sixtIcon,
  coIcon,
  spicedIcon,
  upfIcon,
  minusIcon,
  SSBImage,
  SSBGif,
  cSmall,
  ontologySmall,
  training,
  trainingGif,
  story,
  storyGif,
  github,
  mb,
  mbGif,
  social,
  rwIconGreen,
};

export { media };
