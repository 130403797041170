import { colors } from '../../../styles';
import { GlobeObj } from '../../../types/globeObject';

export const createTooltipElement = (globeObj: GlobeObj) => {
  const tooltip = document.createElement('div');

  const locationLine = document.createElement('div');
  locationLine.textContent = globeObj.location;

  const reasonLine = document.createElement('div');
  reasonLine.textContent = globeObj.reason;

  let additionalReason = null;
  if (globeObj.additionalReason) {
    additionalReason = document.createElement('div');
    additionalReason.textContent = globeObj.additionalReason;
  }

  tooltip.appendChild(locationLine);
  tooltip.appendChild(reasonLine);
  additionalReason && tooltip.appendChild(additionalReason);
  tooltip.style.visibility = 'hidden';
  tooltip.style.width = '135px';
  tooltip.style.backgroundColor = colors.mediumGreen;
  tooltip.style.color = 'white';
  tooltip.style.fontWeight = 'bold';
  tooltip.style.fontSize = '12px';
  tooltip.style.textAlign = 'center';
  tooltip.style.borderRadius = '5px';
  tooltip.style.padding = '5px';
  tooltip.style.position = 'absolute';
  tooltip.style.bottom = '40px';
  tooltip.style.left = '0px';
  tooltip.style.marginLeft = '-60px';
  tooltip.style.opacity = '0';
  tooltip.style.transition = 'opacity 0.3s';

  return tooltip;
};
