import styled from '@emotion/styled';

export const ProjectsComponentContainer = styled.section`
  display: flex;
  justify-content: center;
`;

export const ProjectsDivWrapper = styled.div`
  width: 100%;
  max-width: 96%;
  @media only screen and (min-width: 1280px) {
    /* Styles for screens bigger than 1280px pixels */
    width: 70%;
  }
`;
