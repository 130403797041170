import styled from '@emotion/styled';
import { Avatar, IconButton } from '@mui/material';

import { colors, shadows } from '../../../styles';

export const HeaderComponentContainer = styled.section`
  position: relative;
`;

export const SHeader = styled.header`
  display: flex;
  justify-content: center;
  height: 100vh;
  // border: 2px solid blue;
`;

export const SAvatar = styled(Avatar)`
  box-shadow: 0px 0px 600px 20px ${shadows.greenGlow};
`;

export const SIconButton = styled(IconButton)`
  background-color: white;
  color: black;
  height: 60px;
  width: 60px;
  transition: background-color 0.6s, color 0.6s;
  transform: translateY(-10px);
  &:hover {
    background-color: ${colors.lightGreen};
    color: ${colors.backgroundDark};
  }
  z-index: 1;
`;

export const AboutButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  display: flex;
  justify-content: center;
  /* border: '5px dotted purple'; */
  margin-top: 200px;
`;

export const HeroWrapper = styled.div`
  position: absolute;
  // border: 3px dashed black;
  height: 100%;
  align-items: center;
  // "textAlign" needed since alignItems does not consider the texts, only the Avatar
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
