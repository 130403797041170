import { colors, shadows } from '../../../../styles';
import { baseUrl } from '../../../../urls';
import {
  Anchor,
  AnchorContainer,
  IconContainer,
  StyledIcon,
  StyledPre,
  TimelineContainerH2,
  TimelineContainerH3,
} from './Timeline.styled';

import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import { scroller } from 'react-scroll';

export interface CustomVTElementProps {
  name: string;
  date: string;
  website: string;
  positionOrDegree: string;
  description: string;
  customWasScrolled: boolean;
  customRef: (node?: Element | null | undefined) => void;
  customMediaIconProp: string;
  screenHeightProp: number;
}

export const CustomVTElement: React.FC<CustomVTElementProps> = ({
  name,
  date,
  website,
  positionOrDegree,
  description,
  customWasScrolled,
  customRef,
  customMediaIconProp,
  screenHeightProp,
}) => {
  return (
    <VerticalTimelineElement
      date={date}
      contentStyle={{
        background: colors.lightGreen,
        color: 'white',
        boxShadow: 'none',
      }}
      contentArrowStyle={{
        borderRight: `10px solid  ${colors.lightGreen}`,
      }}
      iconStyle={{
        backgroundImage: `url(${baseUrl}${customMediaIconProp})`,
        backgroundSize: 'cover',
        boxShadow: customWasScrolled
          ? `0px 0px 20px 8px ${shadows.yellowGlow}`
          : 'none',
      }}
      iconOnClick={() => {
        scroller.scrollTo(name, {
          duration: 250,
          smooth: true,
          offset: -(screenHeightProp / 2),
        });
      }}
      intersectionObserverProps={{}}
    >
      <div ref={customRef} id={name} style={{ position: 'relative' }}>
        <StyledPre>
          <AnchorContainer>
            <Anchor href={website} target="_blank" rel="noopener noreferrer">
              <IconContainer>
                <StyledIcon />
              </IconContainer>
              website
            </Anchor>
          </AnchorContainer>
        </StyledPre>
        <TimelineContainerH2>{name}</TimelineContainerH2>
        <TimelineContainerH3>{positionOrDegree}</TimelineContainerH3>
        <p>{description}</p>
      </div>
    </VerticalTimelineElement>
  );
};
