import * as S from './CustomHamburgerMenu.styled';
import profileData from '../../../constants/profileData.json';

import { useState } from 'react';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';

import { colors } from '../../../styles';
import { Footer } from '../Footer/Footer';
import { SocialLink } from '../../../types/profileDataTypes';
import { baseUrl } from '../../../urls';
import { media } from '../../../constants/mediaConstants';

export const CustomHamburgerMenu: React.FC<{
  thresholdWasReachedProp: boolean;
}> = ({ thresholdWasReachedProp }) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const handleClick = () => {
    setIsOpen((prevIsOpenState) => !prevIsOpenState);
  };

  const profileDataMainSocial = profileData.main.social;

  const activeStyle = {
    fontFamily: 'Martian Mono',
    background: colors.hamburgerSelectedGreen,
  };

  return (
    <>
      <S.HamburgerContainer
        thresholdWasReached={thresholdWasReachedProp}
        onClick={handleClick}
      >
        <S.HamburgerIcon />
      </S.HamburgerContainer>
      <Modal open={isOpen} closeAfterTransition>
        <Fade in={isOpen}>
          <S.ModalWrapper>
            <S.CloseMuiIcon onClick={handleClick} />
            <S.ContainerDiv>
              <S.HamburgerHeaderScreenDiv>
                <S.HamburgerLogoImg
                  alt="Rodrigo_Wahl_logo"
                  src={`${baseUrl}${media.rwIconGreen}`}
                />
                <S.NameH1>Rodrigo Wahl</S.NameH1>
                <S.RoleH2>Front-end Developer</S.RoleH2>
              </S.HamburgerHeaderScreenDiv>
              <S.StyledLinkContainer>
                <S.ReactScrollLinkHamburgerButtons
                  to="home"
                  spy={true}
                  smooth={true}
                  duration={500}
                  activeStyle={activeStyle}
                  onClick={handleClick}
                >
                  Home
                </S.ReactScrollLinkHamburgerButtons>
                <S.ReactScrollLinkHamburgerButtons
                  to="about"
                  spy={true}
                  smooth={true}
                  duration={500}
                  activeStyle={activeStyle}
                  onClick={handleClick}
                >
                  About
                </S.ReactScrollLinkHamburgerButtons>
                <S.ReactScrollLinkHamburgerButtons
                  to="resume"
                  spy={true}
                  smooth={true}
                  duration={500}
                  activeStyle={activeStyle}
                  onClick={handleClick}
                >
                  Resume
                </S.ReactScrollLinkHamburgerButtons>
                <S.ReactScrollLinkHamburgerButtons
                  to="projects"
                  spy={true}
                  smooth={true}
                  duration={500}
                  activeStyle={activeStyle}
                  onClick={handleClick}
                >
                  Projects
                </S.ReactScrollLinkHamburgerButtons>
              </S.StyledLinkContainer>
              <S.HamburgerFooterDiv>
                <Footer
                  profileDataMainSocial={profileDataMainSocial as SocialLink[]}
                />
              </S.HamburgerFooterDiv>
            </S.ContainerDiv>
          </S.ModalWrapper>
        </Fade>
      </Modal>
    </>
  );
};
