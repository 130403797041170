import { useAnimation } from 'framer-motion';

import { colors } from '../../../styles';
import * as S from './Navbar.styled';
import { useEffect } from 'react';
import { CustomHamburgerMenu } from './CustomHamburgerMenu';

export const NavBar: React.FC<{ thresholdWasReachedProp: boolean }> = ({
  thresholdWasReachedProp,
}) => {
  const controls = useAnimation();

  useEffect(() => {
    if (thresholdWasReachedProp) {
      controls.start({
        backgroundColor: 'rgba(240, 240, 240 .1)',
        boxShadow: '0 0 15px rgba(0, 0, 0, 0.6)',
        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
        backdropFilter: 'blur(8px)', // Blur effect
        opacity: 0.98,
        transition: {
          duration: 0.3,
        },
      });
    } else {
      controls.start({
        backgroundColor: 'rgba(0, 0, 0, 0)',
        boxShadow: '0 0 0 rgba(0, 0, 0, 0)', // Animatable value
        textShadow: '1px 1px 2px rgba(0, 0, 0, 0.5)',
        backdropFilter: 'blur(0)', // Animatable value
        opacity: 1,
        transition: {
          duration: 0.3,
        },
      });
    }
  }, [thresholdWasReachedProp, controls]);

  // Note, had to replace LI elements with ReactScrollLinkStyled
  return (
    <S.NavElementFramer animate={controls}>
      <S.Ul>
        <S.ReactScrollLinkStyled
          to="home"
          spy={true}
          smooth={true}
          duration={500}
          activeStyle={{ color: colors.lightGreen }}
          thresholdreached={thresholdWasReachedProp.toString()}
        >
          Home
        </S.ReactScrollLinkStyled>
        <S.ReactScrollLinkStyled
          to="about"
          spy={true}
          smooth={true}
          duration={500}
          activeStyle={{ color: colors.lightGreen }}
          thresholdreached={thresholdWasReachedProp.toString()}
        >
          About
        </S.ReactScrollLinkStyled>
        <S.ReactScrollLinkStyled
          to="resume"
          spy={true}
          smooth={true}
          duration={500}
          activeStyle={{ color: colors.lightGreen }}
          thresholdreached={thresholdWasReachedProp.toString()}
        >
          Resume
        </S.ReactScrollLinkStyled>
        <S.ReactScrollLinkStyled
          to="projects"
          spy={true}
          smooth={true}
          duration={500}
          activeStyle={{ color: colors.lightGreen }}
          thresholdreached={thresholdWasReachedProp.toString()}
        >
          Projects
        </S.ReactScrollLinkStyled>
      </S.Ul>
      <CustomHamburgerMenu thresholdWasReachedProp={thresholdWasReachedProp} />
    </S.NavElementFramer>
  );
};
