import * as S from './Resume.styled';

import { LazyMotion, domAnimation } from 'framer-motion';
import { CustomDivider } from '../../organism/Framer/CustomDivider';
import { CustomSectionPreHead } from '../../organism/Framer/CustomSectionPreHead';
import { MyVerticalTimeline } from './MyVerticalTimeline/MyVerticalTimeline';

import { ResumeSection } from '../../../types/profileDataTypes';

interface ResumeProps {
  profileDataResume: ResumeSection;
}

export const Resume: React.FC<ResumeProps> = ({ profileDataResume }) => {
  return (
    <S.ResumeContainer id="resume">
      <S.ResumeWrapper>
        <LazyMotion features={domAnimation} strict>
          <CustomSectionPreHead>Check out</CustomSectionPreHead>
          <CustomDivider>My resume</CustomDivider>
        </LazyMotion>
        <S.TimelineContainer>
          <MyVerticalTimeline resumeData={profileDataResume} />
        </S.TimelineContainer>
      </S.ResumeWrapper>
    </S.ResumeContainer>
  );
};
