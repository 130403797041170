import styled from '@emotion/styled';
import { motion } from 'framer-motion';
import { Link as ReactScrollLink } from 'react-scroll';
import { dimensions } from '../../../styles';

export const NavElementFramer = styled(motion.nav)`
  position: fixed;
  width: 100%;
  height: 60px;
  background: transparent;
  z-index: 5;
  display: flex;
  justify-content: center;
  @media only screen and (max-width: ${dimensions.standardWidthTabletsMobile}) {
    justify-content: end;
  }
  @media only screen and (max-width: ${dimensions.mobileWidthCarousel}) {
    // screens less than 450 pixel
    height: 42px;
  }
`;

export const Ul = styled.ul`
  padding: 0;
  margin: 0;
  list-style: none;
  // border: 2px dashed blue;
  display: flex;
  @media only screen and (max-width: ${dimensions.standardWidthTabletsMobile}) {
    display: none;
  }
`;

export const ReactScrollLinkStyled = styled(ReactScrollLink)<{
  thresholdreached: string;
}>`
  display: flex;
  align-items: center;
  margin: 0;
  padding: 0 25px 0 25px;
  color: white;
  cursor: pointer;
  font-weight: bold;

  :hover {
    background: ${({ thresholdreached }) => {
      return thresholdreached === 'true' ? 'rgba(240, 240, 240, 0.1)' : 'none';
    }};
  }
`;
