import styled from '@emotion/styled';

export const ResumeContainer = styled.section`
  display: flex;
  justify-content: center;
  // border: 10px dashed black;
`;

export const ResumeWrapper = styled.div`
  width: 100%;
  max-width: 96%;
  // border: 1px dashed blue;
  @media only screen and (min-width: 1280px) {
    /* Styles for screens bigger than 1280px pixels */
    width: 70%;
  }
`;

export const TimelineContainer = styled.div`
  padding: 20px 8% 0 0;
  // border: 2px solid white;

  @media only screen and (max-width: 830px) {
    /* Styles for screens smaller than 830 pixels */
    padding: 20px 12% 0 12%;
  }

  @media only screen and (max-width: 650px) {
    /* Styles for screens smaller than 650 pixels */
    padding: 20px 16% 0 16%;
  }

  @media only screen and (max-width: 530px) {
    /* Styles for screens smaller than 530 pixels */
    padding: 20px 18% 0 2%;
  }

  @media only screen and (max-width: 475px) {
    /* Styles for screens smaller than 475 pixels */
    padding: 20px 22% 0 8%;
  }

  @media only screen and (max-width: 405px) {
    /* Styles for screens smaller than 405 pixels */
    padding: 20px 26% 0 10%;
    h2 {
      font-size: 20px;
    }
    h3 {
      font-size: 15px;
    }
    p {
      font-size: 12px;
    }
    span {
      font-size: 8px;
    }
    h2,
    h3,
    p,
    span {
      font-family: monospace;
    }
  }

  @media only screen and (max-width: 325px) {
    /* Styles for screens smaller than 325 pixels */
    h2 {
      font-size: 16px;
    }
    h3 {
      font-size: 12px;
    }
  }
`;
