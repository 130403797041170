/* eslint-disable react-hooks/exhaustive-deps */

import React, { useCallback } from 'react';
import Particles, { IParticlesProps } from 'react-particles';
import { loadFull } from 'tsparticles';
import { Engine } from 'tsparticles-engine';

interface ParticlesComponentProps {
  particlesOptions: IParticlesProps['options'];
  id?: string;
}

export const ParticlesComponent: React.FC<ParticlesComponentProps> = ({
  particlesOptions,
  id,
}) => {
  let isInitialized = false;

  const particlesInit = useCallback(async (engine: Engine) => {
    if (!isInitialized) {
      await loadFull(engine);
      isInitialized = true;
    }
  }, []);

  return <Particles id={id} init={particlesInit} options={particlesOptions} />;
};
