import { colors, shadows } from '../../../../styles';

import { VerticalTimeline } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import LinkIcon from '@mui/icons-material/Link';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import { m } from 'framer-motion';
import { IconButton } from '@mui/material';

const bounceEffect = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-5px);
  }
  60% {
    transform: translateY(5px);
  }
`;

export const rotateAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

export const StyledIcon = styled(LinkIcon)`
  color: ${colors.newDarkGreen};
  width: 15px;
  height: 15px;
  animation: ${rotateAnimation} 2s linear infinite; /* Adjust the animation duration and timing function */
`;

export const IconContainer = styled.div`
  display: inline-block;
  transform: translate(-8px, 3px);
`;

export const Anchor = styled.a`
  color: white;
  text-decoration: none;
`;

export const AnchorContainer = styled.div`
  transition: transform 0.3s ease-in-out;
  font-weight: bold;
  :hover {
    animation: ${bounceEffect} 0.9s ease-in-out;
    transform-origin: center bottom;
  }
`;

export const TimelineContainerH2 = styled.h2`
  margin: 10px 0 2px 0;
  font-size: 23.5px;
  font-family: Martian Mono;
`;

export const TimelineContainerH3 = styled.h3`
  margin: 4px 0 2px 0;
  font-size: 20px;
  font-family: monospace;
`;

export const StyledPre = styled.pre`
  position: absolute;
  top: -38px;
  right: 0;
`;

export const TimelineFilterFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  // width: 100%;
  align-items: start;
  // border: solid 2px gray;
  @media only screen and (max-width: 530px) {
    // border: solid 2px red;
    align-items: start;
  }
`;

export const FiltersFlexDivFramer = styled(m.div)`
  display: flex;
  justify-content: center;
  margin-bottom: 100px;
  width: 100%;
  // border: 2px solid blue;
  @media only screen and (max-width: 1169px) {
    justify-content: start;
    margin-bottom: 70px;
  }
`;

export const MuiTimelineIconButton = styled(IconButton)<{ active: string }>`
  color: white;
  margin: 20px 30px 20px 30px;
  width: 80px;
  height: 50px;
  background: ${({ active }) =>
    active === 'true'
      ? `${colors.mediumGreen}`
      : `rgba(${colors.mediumGreenRgb}, .35)`};
  pointer-events: ${({ active }) => (active === 'true' ? 'none' : 'auto')};
  padding: 12px;
  border-radius: 8px;
  box-shadow: ${({ active }) =>
    active === 'true' ? `0px 0px 10px 3px ${shadows.yellowGlow}` : `none`};

  transition: transform 0.3s ease;
  :hover {
    background: ${colors.lightGreen};
    transform: scale(1.2);
  }
  @media only screen and (max-width: 1169px) {
    margin-right: 25px;
    margin-left: 0px;
    width: 60px;
    height: 30px;
    box-shadow: ${({ active }) =>
      active === 'true'
        ? `0px 0px 4px 3px ${shadows.yellowGlowMobile}`
        : `none`};
  }
  @media only screen and (max-width: 380px) {
    transform: translate(0, 0);
    margin-right: 20px;
  }
`;

export const WorkIconInFilter = styled(WorkIcon)`
  font-size: 30px;
  @media only screen and (max-width: 1169px) {
    font-size: 20px;
  }
`;

export const EduIconInFilter = styled(SchoolIcon)`
  font-size: 30px;
  @media only screen and (max-width: 1169px) {
    font-size: 20px;
  }
`;

export const MuiDivTranslated = styled.div`
  margin-left: 5px;
`;

export const StyledWorkIcon = styled(WorkIcon)`
  position: absolute;
  font-size: 92px;
  top: -90px;
  left: 50%;
  color: ${colors.lightGreenTransparent};
  transform: translateX(-46px);
  @media only screen and (max-width: 1169px) {
    /* Styles for screens smaller than 1169 pixels */
    font-size: 70px;
    top: -65px;
    left: 31px;
  }
`;

export const StyledEduIcon = styled(SchoolIcon)`
  position: absolute;
  font-size: 92px;
  top: -90px;
  left: 50%;
  color: ${colors.lightGreenTransparent};
  transform: translateX(-46px);
  @media only screen and (max-width: 1169px) {
    /* Styles for screens smaller than 1169 pixels */
    font-size: 70px;
    top: -65px;
    left: 31px;
  }
`;

export const VerticalTimelinePositioned = styled(VerticalTimeline)`
  position: relative;
  margin-bottom: 100px;
`;

export const FilterButtonsDivFlexed = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  // border: 2px dashed orange;
  @media only screen and (max-width: 1169px) {
    align-items: start;
  }
`;

export const FilterBySpan = styled.span`
  font-family: Martian Mono !important;
  font-size: 20px !important;
  color: ${colors.lightGreenMediumTransparent};
  transform: translateX(-4px);
  @media only screen and (min-width: 1169px) {
    transform: translateX(2px);
  }
`;
