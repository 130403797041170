import { VerticalTimelineElement } from 'react-vertical-timeline-component';
import { baseUrl } from '../../../../urls';
import { media } from '../../../../constants/mediaConstants';
import { useEffect, useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { colors } from '../../../../styles';
import styled from '@emotion/styled';

const endIntersectionConfig = {
  rootMargin: '0% 0% -50% 0%',
};

export const EndVTElement = () => {
  const [endWasScrolled, setEndWasScrolled] = useState<boolean>(false);

  const [endRef, endInView] = useInView(endIntersectionConfig);

  useEffect(() => {
    setEndWasScrolled(endInView);
  }, [endInView]);

  const transition = endWasScrolled
    ? 'background-color 0.2s ease-in-out'
    : 'background-color 0.2s ease-in-out';

  return (
    <div
      ref={endRef}
      style={{ position: 'relative' }}
      className="last-vte-custom-parent"
    >
      <ChevronDownVTLElement
        iconStyle={{
          backgroundImage: `url(${baseUrl}${media.minusIcon})`,
          backgroundSize: 'cover',
          transition: transition,
          backgroundColor: endWasScrolled
            ? colors.endOfTimelineDarkGreen
            : colors.lightGreen,
          height: '80px',
          width: '80px',
          boxShadow: 'none',
        }}
      />
    </div>
  );
};

const ChevronDownVTLElement = styled(VerticalTimelineElement)`
  transform: translateY(50px);
`;
