import { Modal, Fade } from '@mui/material';
import GitHubIcon from '@mui/icons-material/GitHub';
import CloseIcon from '@mui/icons-material/Close';
import VisibilityIcon from '@mui/icons-material/Visibility';
import styled from '@emotion/styled';

import { PortfolioProject } from '../../../types/profileDataTypes';
import { colors, dimensions } from '../../../styles';
import { media } from '../../../constants//mediaConstants';
import { baseUrl } from '../../../urls';

export interface CustomCarouselModalProps {
  isOpen: boolean;
  projectData: PortfolioProject | null;
  parentClose: () => void;
}

/* To open this Carousel, BOTH isOpen and selectedProject States of the parent are passed. BOTH need to be deactivated on close  */

export const CustomCarouselModal: React.FC<CustomCarouselModalProps> = ({
  isOpen,
  projectData,
  parentClose,
}) => {
  let mainMedia = '';

  if (projectData?.id === 'social') {
    mainMedia = media.social;
  } else if (projectData?.id === 'mb') {
    mainMedia = media.mb;
  } else if (projectData?.id === 'github') {
    mainMedia = media.github;
  } else if (projectData?.id === 'story') {
    mainMedia = media.story;
  } else if (projectData?.id === 'training') {
    mainMedia = media.training;
  } else if (projectData?.id === 'data') {
    mainMedia = media.cSmall;
  } else if (projectData?.id === 'ontology') {
    mainMedia = media.ontologySmall;
  } else if (projectData?.id === 'ssb') {
    mainMedia = media.SSBImage;
  }

  return (
    <Modal
      sx={{
        // SX closes the backdrop faster than regular element
        '& .MuiModal-backdrop': {
          backgroundColor: 'rgba(17, 17, 17, 0.14)',
          position: 'relative',
          height: '100%',
          width: '100%',
        },
      }}
      open={isOpen}
      onClose={parentClose}
    >
      <Fade in={isOpen} timeout={300} style={{ outline: 'none' }}>
        <ModalContainer>
          <ModalContent>
            <CloseIconModal onClick={parentClose} />
            <ModalHeaderTag>
              {projectData?.time} - {projectData?.type}
            </ModalHeaderTag>
            <H1Styled>{projectData?.cardTitle}</H1Styled>
            <div
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <ImageContainerDiv>
                <ImgEl src={`${baseUrl}${mainMedia}`} alt="project_image" />
              </ImageContainerDiv>
              <DescriptionDiv>
                <PositionedDivContainer>
                  {!!projectData?.deployedLink && (
                    <a
                      href={projectData?.deployedLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <MuiDemoIcon />
                    </a>
                  )}
                  {!!projectData?.githubUrl && (
                    <a
                      href={projectData?.githubUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <SourceCodeIcon />
                    </a>
                  )}
                </PositionedDivContainer>
                <FlexDescriptionContainer>
                  {projectData?.modalDescription}
                  <TechContainer>
                    {projectData?.stack.map((tech, index) => {
                      return (
                        <div
                          style={{
                            display: 'inline-block',
                            paddingTop: '4px',
                          }}
                          key={index}
                        >
                          <ModalTagLabels>{tech}</ModalTagLabels>
                        </div>
                      );
                    })}
                  </TechContainer>
                </FlexDescriptionContainer>
              </DescriptionDiv>
            </div>
          </ModalContent>
        </ModalContainer>
      </Fade>
    </Modal>
  );
};

const FlexDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  // border: pink solid;
`;

const MuiDemoIcon = styled(VisibilityIcon)`
  cursor: pointer;
  color: white;
  font-size: 15px;
  :hover {
    color: ${colors.standardGreen};
    transform: scale(1.3);
    transition: transform 0.2s ease-in-out;
  }
`;

const SourceCodeIcon = styled(GitHubIcon)`
  cursor: pointer;
  color: white;
  font-size: 15px;
  margin-left: 10px;
  :hover {
    color: ${colors.standardGreen};
    transform: scale(1.3);
    transition: transform 0.2s ease-in-out;
  }
`;
const ModalContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  user-select: none;
  background: ${colors.newLighterGreen};
  padding: 20px;
  border-radius: 20px;
  height: 90%;
  max-height: 650px;
  width: 90%;
  max-width: 450px;
`;

const ModalContent = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ImageContainerDiv = styled.div`
  display: flex;
  flex-grow: 1;
  justify-content: center;
  // border: 2px solid red;
  height: 150px;
  margin-bottom: 30px;
`;

const DescriptionDiv = styled.div`
  // border: 2px solid white;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 200px;
  background: ${colors.lightGreen};
  border-radius: 5px;
  overflow: auto;
  padding: 20px 15px 15px 15px;
  font-size: 14px;

  @media only screen and (min-width: 400px) {
    font-size: 15px;
  }
  @media only screen and (min-width: 500px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 850px) {
    font-size: 18px;
  }
  @media only screen and (min-width: 2500px) {
    font-size: 20px;
  }
`;

const PositionedDivContainer = styled.div`
  position: absolute;
  display: flex;
  justify-content: end;
  top: 2px;
  right: 10px;
  @media only screen and (max-width: 600px) {
    //less than 600 px
    top: 4px;
  }
`;

const TechContainer = styled.div`
  margin-top: 10px;
  padding-bottom: 10px;
`;

const H1Styled = styled.h1`
  font-size: 30px;
  margin: 5px 15px 20px 0;
  @media only screen and (max-width: 600px) {
    //less than 600 px
    font-size: 25px;
  }
  @media only screen and (min-width: 900px) {
    //more than 2000 px
    font-size: 40px;
  }
`;

const ImgEl = styled.img`
  border-radius: 10px;
  max-width: 100%;
`;

const ModalHeaderTag = styled.span`
  font-weight: bold;
  padding: 3px 6px;
  border-radius: 5px;
  width: fit-content;
  max-width: 80%;
  background: ${colors.lightGreen};

  font-size: 14px;

  @media only screen and (min-width: 400px) {
    font-size: 15px;
  }
  @media only screen and (min-width: 500px) {
    font-size: 16px;
  }
  @media only screen and (min-width: 850px) {
    font-size: 18px;
  }
  @media only screen and (min-width: 2500px) {
    font-size: 20px;
  }
`;

const ModalTagLabels = styled.span`
  font-size: 80%;
  font-weight: bold;
  padding: 3px 6px;
  margin-right: 4px;
  margin-top: 13px !important;
  border-radius: 8px;
  background: ${colors.techLabelGreen};
`;

const CloseIconModal = styled(CloseIcon)`
  position: absolute;
  color: ${colors.techLabelGreenDarker};
  top: 6px;
  right: 10px;
  font-size: 60px;
  cursor: pointer;
  &:hover {
    color: white;
  }
  @media only screen and (max-width: ${dimensions.mobileWidthCarousel}) {
    // screens less than 450 pixel
    font-size: 40px;
  }
`;
