import { useEffect, useState } from 'react';
import { Link as ReactScrollLink } from 'react-scroll';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typewriter from 'react-ts-typewriter';
import { LazyMotion, domAnimation, m } from 'framer-motion';

import { heroOptions } from '../../particles/ParticleOptions';
import { ParticlesComponent } from '../../particles/ParticleComponent';
import * as S from './Header.styled';
import { NavBar } from './NavBar';
import { media } from '../../../constants/mediaConstants';
import { baseUrl } from '../../../urls';
import { colors } from '../../../styles';

/* Header Component also contains the home or top page introduction. Note the structure, it is a relative div which contains two elements, the dynamic nav element(pos. absolute or fixed depending on scroll height) and a header element which contains the home content */

export const Header = () => {
  const [showTypewriter, setShowTypewriter] = useState<boolean>(false);
  const [typewriterMainIsPending, setTypewriterMainIsPending] =
    useState<boolean>(true);
  const [showSecondTypewriter, setShowSecondTypewriter] =
    useState<boolean>(false);
  const [typewriterSecondIsPending, setTypewriterSecondIsPending] =
    useState<boolean>(true);
  const [thresholdWasReached, setThresholdReached] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollY = window.scrollY;
      if (scrollY > 100) {
        setThresholdReached(true);
      } else {
        setThresholdReached(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    const timeoutId = setTimeout(() => {
      setShowTypewriter(true);
    }, 500);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      clearTimeout(timeoutId);
    };
  }, []);

  const handleOnFinished = () => {
    setTypewriterMainIsPending(false);
    setTimeout(() => setShowSecondTypewriter(true), 1000);
  };

  return (
    <S.HeaderComponentContainer>
      <NavBar thresholdWasReachedProp={thresholdWasReached} />
      <S.SHeader id="home">
        <ParticlesComponent id="home" particlesOptions={heroOptions as any} />

        <S.HeroWrapper>
          <LazyMotion features={domAnimation}>
            <m.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <S.SAvatar
                alt="Rodrigo Avatar Picture"
                src={`${baseUrl}${media.profilePic}`}
                sx={{ height: '200px', width: '200px' }}
              />
            </m.div>

            <m.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 1 }}
            >
              <h1
                style={{
                  color: colors.lightGreen,
                  fontWeight: '100',
                  fontSize: '40px',
                  fontFamily: 'Martian Mono',
                }}
              >
                Rodrigo Wahl
              </h1>
            </m.div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                fontFamily: 'Share Tech Mono',
                fontSize: '20px',
                fontWeight: '600',
              }}
            >
              {showTypewriter && (
                <Typewriter
                  speed={40}
                  text="Frontend Web Developer"
                  onFinished={handleOnFinished}
                  cursor={typewriterMainIsPending}
                />
              )}
              {showSecondTypewriter && (
                <div style={{ marginTop: '15px' }}>
                  <Typewriter
                    speed={40}
                    text="Highly experienced with React & Typescript"
                    onFinished={() => {
                      setTypewriterSecondIsPending(false);
                    }}
                    cursor={typewriterSecondIsPending}
                  />
                </div>
              )}
            </div>
          </LazyMotion>
        </S.HeroWrapper>
        <S.AboutButtonWrapper>
          <ReactScrollLink to="about" smooth={true} duration={500}>
            <S.SIconButton>
              <ExpandMoreIcon
                sx={{ fontSize: '40px', transform: 'translateY(2px)' }}
              />
            </S.SIconButton>
          </ReactScrollLink>
        </S.AboutButtonWrapper>
      </S.SHeader>
    </S.HeaderComponentContainer>
  );
};
