import {
  EduIconInFilter,
  FiltersFlexDivFramer,
  MuiTimelineIconButton,
  TimelineFilterFlexContainer,
  WorkIconInFilter,
  FilterButtonsDivFlexed,
  FilterBySpan,
} from './Timeline.styled';

import { LazyMotion, domAnimation } from 'framer-motion';

export interface TimelineFilterProps {
  isExperience: boolean;
  handleExperience: () => void;
  isEducation: string;
  handleEducation: () => void;
}

export const TimelineFilter: React.FC<TimelineFilterProps> = ({
  isExperience,
  handleExperience,
  isEducation,
  handleEducation,
}) => {
  return (
    <LazyMotion features={domAnimation} strict>
      <TimelineFilterFlexContainer>
        <FiltersFlexDivFramer
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          transition={{
            delay: 0.3,
          }}
        >
          <FilterButtonsDivFlexed>
            <FilterBySpan>Filter by</FilterBySpan>
            <div>
              <MuiTimelineIconButton
                active={isExperience.toString()}
                onClick={handleExperience}
              >
                <WorkIconInFilter />
              </MuiTimelineIconButton>
              <MuiTimelineIconButton
                active={isEducation.toString()}
                onClick={handleEducation}
              >
                <EduIconInFilter />
              </MuiTimelineIconButton>
            </div>
          </FilterButtonsDivFlexed>
        </FiltersFlexDivFramer>
      </TimelineFilterFlexContainer>
    </LazyMotion>
  );
};
