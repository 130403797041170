import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectCoverflow, FreeMode, Pagination } from 'swiper';
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import useMediaQuery from '@mui/material/useMediaQuery';
import { LazyMotion, domAnimation } from 'framer-motion';
import { m } from 'framer-motion';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import styled from '@emotion/styled';
import { colors, dimensions, shadows } from '../../../styles';
import { keyframes } from '@emotion/react';
import { PortfolioProject } from '../../../types/profileDataTypes';
import { baseUrl } from '../../../urls';
import { media } from '../../../constants/mediaConstants';
import { useState } from 'react';
import { CustomCarouselModal } from './CustomCarouselModal';

export const CustomCarousel = ({
  projectsArrayProp,
}: {
  projectsArrayProp: PortfolioProject[];
}) => {
  const [selectedProject, setSelectedProject] =
    useState<PortfolioProject | null>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const isAbove1600pxWidth = useMediaQuery('(min-width:1600px)');
  SwiperCore.use([EffectCoverflow, FreeMode, Pagination]);
  const pagination = {
    clickable: true,
    renderBullet: function (index: any, className: any) {
      return `<span class=${className}></span>`;
    },
  };

  const handleClick = (projectObj: PortfolioProject) => {
    setSelectedProject((prevProject) => {
      // Ensure that the state is only set once
      if (prevProject !== projectObj) {
        return projectObj;
      }
      return prevProject;
    });
    if (isOpen !== true) {
      setIsOpen(true);
    }
  };

  return (
    <LazyMotion features={domAnimation} strict>
      <m.div
        initial={{ opacity: 0 }}
        whileInView={{ opacity: 1 }}
        transition={{
          delay: 0.3,
        }}
      >
        <CarouselContainerDiv>
          <SwiperStyled
            spaceBetween={28}
            pagination={pagination}
            freeMode={{
              sticky: true,
              momentum: true,
              minimumVelocity: 200.8,
              momentumRatio: 0.001,
              momentumVelocityRatio: 0.05,
            }}
            coverflowEffect={{
              rotate: 0,
              scale: 1,
              stretch: 8,
              depth: 200,
              modifier: 2.5,
              slideShadows: true,
            }}
            effect={isAbove1600pxWidth ? 'slide' : 'coverflow'}
            speed={800}
            grabCursor={true}
            style={{
              marginBottom: '100px',
              padding: '40px 0 40px 0',
            }}
            centeredSlides={true}
            loop={true}
            slidesPerView={'auto'}
          >
            {projectsArrayProp.map((projectObj) => {
              let dynamicMediaURL = '';
              let dynamicGifUrl = '';

              if (projectObj.id === 'social') {
                dynamicMediaURL = media.social;
              } else if (projectObj.id === 'mb') {
                dynamicMediaURL = media.mb;
                dynamicGifUrl = media.mbGif;
              } else if (projectObj.id === 'github') {
                dynamicMediaURL = media.github;
              } else if (projectObj.id === 'story') {
                dynamicMediaURL = media.story;
                dynamicGifUrl = media.storyGif;
              } else if (projectObj.id === 'training') {
                dynamicMediaURL = media.training;
                dynamicGifUrl = media.trainingGif;
              } else if (projectObj.id === 'data') {
                dynamicMediaURL = media.cSmall;
              } else if (projectObj.id === 'ontology') {
                dynamicMediaURL = media.ontologySmall;
              } else if (projectObj.id === 'ssb') {
                dynamicMediaURL = media.SSBImage;
                dynamicGifUrl = media.SSBGif;
              }
              return (
                <SwiperSlideStyled key={projectObj.id} gif={dynamicGifUrl}>
                  <Overlay className="overlay_blocker" />
                  <SlideContent>
                    <SlideImageContainerDiv>
                      <CardImageDiv
                        projectImage={dynamicMediaURL}
                        className="card_image"
                      />
                      <H2SlideTitle>{projectObj.cardTitle}</H2SlideTitle>
                    </SlideImageContainerDiv>
                    <DescriptionDivContainer>
                      <DescriptionChildDivWrapper className="description_child_wrapper">
                        <PStyled>{projectObj.modalDescriptionSmall}</PStyled>
                        <BottomDescription>
                          <div>
                            {projectObj.stackSmall.map((tech, index) => (
                              <SpanStyled key={index}>{tech}</SpanStyled>
                            ))}
                          </div>
                          <SpanStyled>{projectObj.time}</SpanStyled>
                        </BottomDescription>
                      </DescriptionChildDivWrapper>
                    </DescriptionDivContainer>
                    <ViewMoreFlexContainer>
                      <PreStyled
                        style={{ cursor: 'pointer' }}
                        onClick={() => handleClick(projectObj)}
                      >
                        More Details
                      </PreStyled>
                      <ChevronDownIcon
                        onClick={() => handleClick(projectObj)}
                      />
                    </ViewMoreFlexContainer>
                  </SlideContent>
                  <CustomCarouselModal
                    isOpen={isOpen}
                    projectData={selectedProject}
                    parentClose={() => {
                      setSelectedProject(null);
                      setIsOpen(false);
                    }}
                  />
                </SwiperSlideStyled>
              );
            })}
          </SwiperStyled>
        </CarouselContainerDiv>
      </m.div>
    </LazyMotion>
  );
};

export const ChevronDownIcon = styled(KeyboardArrowDownIcon)`
  cursor: pointer;
  font-size: 20px;
`;

export const PreStyled = styled.pre`
  font-family: 'Roboto Mono';
  font-size: 12px;
  font-weight: bolder;
  margin: 0;
  :hover {
    transition: transform 0.2s ease-in-out, color 0.2s ease-in-out;
    transform: scale(1.1);
  }
`;

export const ViewMoreFlexContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 10px; // had to do this way to avoid z-index issues (position and flex do not work)
  @media only screen and (max-width: ${dimensions.mobileWidthCarousel}) {
    margin-top: 15px;
  }
`;

export const BottomDescription = styled.div`
  // border: 1px solid red;
  display: flex;
  justify-content: space-between;
`;

export const SpanStyled = styled.span`
  font-size: 12px;
  font-weight: bold;
  padding: 3px 6px;
  margin-right: 4px;
  border-radius: 8px;
  background: ${colors.techLabelGreen};
`;

export const PStyled = styled.p`
  color: white;
  font-size: 13px;
  font-weight: bold;
  margin: 10px;
`;

export const DescriptionChildDivWrapper = styled.div`
  // border: 2px solid blue;
  position: relative;
  width: 80%;
  border-radius: 10px;
  background-color: ${colors.carouselCardDarkBackgroundGreen};
  div:last-child {
    width: 100%;
    position: absolute;
    bottom: 6px;
    padding-left: 6px;
    padding-right: 6px;
  }
  @media only screen and (max-width: ${dimensions.mobileWidthCarousel}) {
    height: 132px;
  }
`;

export const DescriptionDivContainer = styled.div`
  // border: 1px solid yellow;
  height: 125px;
  display: flex;
  justify-content: center;
`;

export const H2SlideTitle = styled.h2`
  margin: 15px 0 4px 0;
  color: ${colors.newDarkGreen};
  font-size: 16.5px;
  font-family: Martian Mono;
  @media only screen and (max-width: ${dimensions.mobileWidthCarousel}) {
    /* Styles for screens smaller than 450 pixels */
    font-size: 14px;
  }
`;

export const SlideContent = styled.div`
  height: 100%;
  user-select: none;
`;

export const SlideImageContainerDiv = styled.div`
  // border: 2px solid blue;
  margin: 20px 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const CardImageDiv = styled.div<{ projectImage: String }>`
  height: 200px;
  width: 200px;
  border-radius: 10px;
  background-image: ${({ projectImage }) => ` url(${baseUrl}${projectImage});`}
  background-size: cover;
`;

export const CarouselContainerDiv = styled.div`
  display: flex;
  justify-content: center;
`;

export const SwiperStyled = styled(Swiper)`
  padding-bottom: 50px !important;
  @media only screen and (min-width: 1650px) {
    /* Styles for screens larger than 1650 pixels */
    max-width: 1150px;
  }
  div.swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    padding: 4px;
    transform: translateY(10px);
  }
`;

const wiggle = keyframes`
  0% { transform: rotate(0); }
  25% { transform: rotate(5deg); }
  50% { transform: rotate(-5deg); }
  75% { transform: rotate(3deg); }
  100% { transform: rotate(0); }
`;

const wiggleVertically = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-4px);
  }
`;

export const SwiperSlideStyled = styled(SwiperSlide)<{
  gif: String | undefined;
}>`
  position: relative;
  border-radius: 20px;
  width: 350px !important;
  height: 450px !important;
  background: ${colors.mediumGreen};
  &.swiper-slide-active {
    svg {
      animation: ${wiggleVertically} 1s infinite;
    }
    background: ${colors.newLighterGreen};
    box-shadow: 0 0 35px ${shadows.greenGlow};
    .description_child_wrapper {
      background: ${colors.lightGreen};
    }
    div.overlay_blocker {
      display: none;
    }
    div.card_image {
      background: ${({ gif }) => (gif!! ? `url(${baseUrl}${gif})` : '')};
      background-size: cover;
    }
    animation: ${wiggle} 0.5s ease 325ms; /* Adjust duration and easing as needed */
    transform-origin: center;
  }
  @media only screen and (max-width: ${dimensions.mobileWidthCarousel}) {
    /* Styles for screens smaller than 450 pixels */
    width: 260px !important;
  }
`;

export const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background: transparent;
  backdrop-filter: blur(1.5px);
  z-index: 1;
`;
