import { Fade } from '@mui/material';
import { useInView } from 'react-intersection-observer';

export const ScrollFadeWrapper = ({
  children,
  timeoutDuration = 1000,
  direction = 'none',
}) => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    threshold: 0.5,
  });
  return (
    <div ref={ref}>
      <Fade in={inView} timeout={timeoutDuration}>
        {children}
      </Fade>
    </div>
  );
};
