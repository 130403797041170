import { LazyMotion, domAnimation } from 'framer-motion';
import * as S from './Projects.styled';
import { CustomSectionPreHead } from '../../organism/Framer/CustomSectionPreHead';
import { CustomDivider } from '../../organism/Framer/CustomDivider';
import { PortfolioSection } from '../../../types/profileDataTypes';
import { CustomCarousel } from './CustomCarousel';

export interface ProjectsProps {
  profileDataPortfolio: PortfolioSection;
}

export const Projects: React.FC<ProjectsProps> = ({ profileDataPortfolio }) => {
  return (
    <S.ProjectsComponentContainer id="projects">
      <S.ProjectsDivWrapper>
        <LazyMotion features={domAnimation} strict>
          <CustomSectionPreHead>Check out my</CustomSectionPreHead>
          <CustomDivider>Projects</CustomDivider>
        </LazyMotion>
        <CustomCarousel projectsArrayProp={profileDataPortfolio.projects} />
      </S.ProjectsDivWrapper>
    </S.ProjectsComponentContainer>
  );
};
