import { ReactNode } from 'react';
import { colors } from '../../../styles';
import styled from '@emotion/styled';

interface CustomSectionPreHeadProps {
  children: ReactNode;
}

export const CustomSectionPreHead: React.FC<CustomSectionPreHeadProps> = ({
  children,
}) => {
  return (
    <div>
      <StyledH1>{children}</StyledH1>
    </div>
  );
};

export const StyledH1 = styled.h1`
  padding-left: 50px;
  margin: 0 0 0 0;
  color: ${colors.standardGreen};
  font-size: 30px;
  font-family: 'Martian Mono';
  @media only screen and (max-width: 715px) {
    /* Styles for screens smaller than 715 pixels */
    padding-left: 0;
  }
`;
