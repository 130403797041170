import * as S from './About.styled';
import { MainSection } from '../../../types/profileDataTypes';
import { media } from '../../../constants/mediaConstants';
import { baseUrl } from '../../../urls';

import { LazyMotion, domAnimation } from 'framer-motion';
import { GlobeComponent } from '../../organism/GlobeComponent/GlobeComponent';

import DownloadIcon from '@mui/icons-material/Download';
import { CustomDivider } from '../../organism/Framer/CustomDivider';
import { CustomSectionPreHead } from '../../organism/Framer/CustomSectionPreHead';
import { ScrollFadeWrapper } from '../../helpers/ScrollFadeWrapper';

export interface AboutProps {
  profileDataMain: MainSection;
}

export const About: React.FC<AboutProps> = ({ profileDataMain }) => {
  const handleDownload = (): void => {
    const fileUrl = `${baseUrl}${media.CV}`;

    const anchor = document.createElement('a');
    anchor.href = fileUrl;
    anchor.download = 'CV_Rodrigo_Wahl.pdf';

    document.body.appendChild(anchor);

    // Programmatically click the anchor
    anchor.click();
    // Remove the anchor from the document
    document.body.removeChild(anchor);
  };

  return (
    <S.AboutComponentContainer id="about">
      <S.AboutMeDivWrapper>
        <CustomSectionPreHead>Learn more</CustomSectionPreHead>
        <CustomDivider>About me</CustomDivider>
        <S.AboutFlexContainer>
          <S.FirstCell>
            <S.FirstCellFirstContainerFramer>
              <S.DetailsH1>
                {profileDataMain.professionalDetailsTitle}
              </S.DetailsH1>
              <p>{profileDataMain.bioIntro}</p>
              <p>{profileDataMain.bio}</p>
              <p>{profileDataMain.bioMiddle}</p>
            </S.FirstCellFirstContainerFramer>
            <S.FirstCellSecondContainerFramer>
              <p>{profileDataMain.bioEnding}</p>
              <p>{profileDataMain.findOutMore}</p>
              <S.MuiButton variant="contained" onClick={handleDownload}>
                <DownloadIcon />
                Download CV
              </S.MuiButton>
            </S.FirstCellSecondContainerFramer>
          </S.FirstCell>
          <S.SecondCell>
            <S.SecondCellFirstContainerFramer>
              <S.DetailsH1>{profileDataMain.personalDetailsTitle}</S.DetailsH1>
              <p>{profileDataMain.personalInfo}</p>
              <p>{profileDataMain.personalInfoMiddle}</p>
              <p>{profileDataMain.personalInfoEnding}</p>
            </S.SecondCellFirstContainerFramer>
            <ScrollFadeWrapper>
              <S.GlobeContainerTranslateWrapper>
                <S.GlobeContainerFramer>
                  <GlobeComponent />
                </S.GlobeContainerFramer>
              </S.GlobeContainerTranslateWrapper>
            </ScrollFadeWrapper>
          </S.SecondCell>
        </S.AboutFlexContainer>
      </S.AboutMeDivWrapper>
    </S.AboutComponentContainer>
  );
};
