import { Divider } from '@mui/material';
import { colors } from '../../../styles';
import { ReactNode } from 'react';

interface CustomDividerProps {
  children: ReactNode;
}

export const CustomDivider: React.FC<CustomDividerProps> = ({ children }) => {
  return (
    <div>
      <Divider
        light={true}
        textAlign="left"
        sx={{
          '&::before, &::after': {
            borderColor: colors.lightGreen,
          },
        }}
      >
        <h1
          style={{
            marginTop: '15px',
            color: colors.lightGreen,
            fontSize: '40px',
            fontFamily: 'Martian Mono',
          }}
        >
          {children}
        </h1>
      </Divider>
    </div>
  );
};
