import { colors } from '../../../../styles';

import styled from '@emotion/styled';
import WorkIcon from '@mui/icons-material/Work';
import SchoolIcon from '@mui/icons-material/School';
import { VerticalTimeline } from 'react-vertical-timeline-component';

export const StyledWorkIcon = styled(WorkIcon)`
  position: absolute;
  font-size: 92px;
  top: -90px;
  left: 50%;
  color: ${colors.lightGreenTransparent};
  transform: translateX(-46px);
  @media only screen and (max-width: 1169px) {
    /* Styles for screens smaller than 1169 pixels */
    font-size: 70px;
    top: -65px;
    left: 31px;
  }
`;

export const StyledEduIcon = styled(SchoolIcon)`
  position: absolute;
  font-size: 92px;
  top: -90px;
  left: 50%;
  color: ${colors.lightGreenTransparent};
  transform: translateX(-46px);
  @media only screen and (max-width: 1169px) {
    /* Styles for screens smaller than 1169 pixels */
    font-size: 70px;
    top: -65px;
    left: 31px;
  }
`;

export const VerticalTimelinePositioned = styled(VerticalTimeline)`
  position: relative;
  margin-bottom: 100px;
`;
